@import '../colors.css';
@import '../../fonts/Proxima-Nova_Regular.css';
@import '../../fonts/PPMori.css';

body {
    margin: 0px;
    padding: 0px;
}

body * {
    font-family: 'PPMori', Arial, Courier, sans-serif !important;
}

.container {
    /* background-color: var(--ultra-light-gray); */
    /* background-image: url("../images/background.png"); */
    background-color: var(--live-violet);
    background-size: cover;
    background-position: right top;
    min-height: 100vh;
}

.container.noBackground {
    background-image: none;
}

.container .link {
    color: var(--violet);
    cursor: pointer;
    text-decoration: none;
}

.container .link:hover {
    color: var(--dark-violet);
    text-decoration: underline;
}

.container * {
    font-family: 'PPMori', Arial, Courier, sans-serif !important;
}

.listTitle {
    font-weight: 600;
}

.listInfo {
    font-size: 0.8rem;
    color: var(--dark-gray);
}

.pageHeaderContainer {
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    color: var(--strat-white);
}

.pageHeaderContainer div:nth-child(1) {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--violet);
}

.simpleList {
    border: 0px solid #aaa;
    border-radius: 5px;
}

.simpleList svg {
    cursor: pointer;
}

.simpleList .simpleListHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #666;
    font-weight: 600;
}

.simpleList .simpleListRow {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.simpleList .simpleListRow:hover {
    background-color: #f5f5f5;
}

.simpleList > div {
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px dotted #bbb;
}

.simpleList > div:first-child {
    border-radius: 5px 5px 0px 0px;
}

.simpleList > div:last-child {
    border-bottom: 0px;
    border-radius: 0px 0px 5px 5px;
}

.yellowButton {
    background-color: var(--yellow) !important;
    font-weight: 600 !important;
    color: var(--black) !important;
}

.yellowButton:hover {
    background-color: var(--dark-yellow) !important;
}

.lightGreenRoundedButton {
    background: var(--live-green);
    border-radius: 20px;
    color: var(--live-violet);
}

.whiteRoundedButton {
    background: var(--live-white);
    border-radius: 20px;
    color: var(--live-violet);
}

.listPaging {

    width: 100%;
    display: flex;
    justify-content: space-between;

    .info {
        color: var(--gray);
        padding: 0px 16px;
        font-size: 14px;
    }

}

.listFilter {
    background-color: #fff;
    margin-bottom: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;

    .items {
        width: 100%;
        display: flex;
        gap: 15px;
    }

}

.centeredContent {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.auditLogos {
    position: absolute;
    top: 20px;
    left: calc(50% + 310px);
    display: flex;
    flex-direction: column;

    @media (max-width: 1000px) {
        display: none;
    }
}

.circleButton {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    color: var(--dark-gray);
    background-color: var(--live-green) !important;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--dark-gray);
}

.circleButton:hover {
    color: var(--black);
    background-color: var(--live-green) !important;
    border: 1px solid var(--black);
    filter: drop-shadow(2px 2px 1px #888);
}

.link {
    cursor: pointer;
    color: var(--violet);
    text-decoration: underline;
}

.link:hover {
    font-weight: bold;
}

.langSwitch {
    background: white;
    color: black;
    font-weight: 600;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.strategyContainer {
    background-color: var(--ultra-light-gray);
    background-size: cover;
    background-position: right top;
    min-height: 100vh;
}

.loggedUser {
    font-size: 0.9rem;
    font-weight: 600;
    text-align: right;
    margin-right: 10px;
}