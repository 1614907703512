@import '../colors.css';

.startButton {
	background-color: var(--green);
	color: white;
	cursor: pointer;
	border-radius: 5px;
	padding: 2px 10px 2px 15px;
	display: flex;
	align-items: center;
}

.startButton:hover {
	background-color: var(--dark-green);
}

.stopButton {
	background-color: var(--red);
	color: white;
	cursor: pointer;
	border-radius: 5px;
	padding: 2px 10px 2px 15px;
	display: flex;
	align-items: center;
}

.stopButton:hover {
	background-color: var(--dark-red);
}

.onAir {
	background-color: #ff0000;
	max-width: 340px;
	margin: auto;
	padding: 20px;
	box-sizing: border-box;
	font-size: 1.2rem;
	color: white;
	text-align: center;
	border: 3px solid white;
	border-radius: 15px;
	font-weight: 600;
	letter-spacing: 5px;
}

.onAirInfo {
	font-size: 0.9rem;
	color: var(--red);
}

.relayItemCard {
	margin-bottom: 10px;
}

.relayItemActions {
	padding: 0 16px 16px 16px !important;
}

.formLabel {
	color: var(--violet) !important;
	border-bottom: 1px solid #eee;
	padding-bottom: 10px !important;
	margin-bottom: 5px !important;
}

.groupContainer {
	margin-bottom: 10px;
}

.groupContainer .groupHeader {
	background-color: #D4AFEE;
}

.groupContainer .formLabel {
	border-bottom: 0px;
}

.groupContainer .groupHeader span {
	font-size: inherit;
	font-weight: 700;
	color: var(--violet) !important;
}

.groupContainer .relayItemCard {
	margin-bottom: 0;
	border: none;
	box-shadow: none;
	padding-bottom: 15px;
	border-bottom: 1px solid #eee;
}

.groupContainer .relayItemCard:last-child {
	border-bottom: 0px;
	padding-bottom: 0px;
}

.groupContainer .relayItemCard > div {
	padding: 0;
}

.groupContainer .relayItemCard + .relayItemCard > div {
	padding-top: 24px;
}

.scaleGroup {
	justify-content: space-between;
	padding-top: 12px;
}

.scaleGroup .scaleOption {
	border-radius: 50% 50% !important;
	min-width: 2em;
	padding: 0 !important;
	margin: 0 !important;
	border: 1px solid rgba(0, 0, 0, 0.12) !important;
	color: var(--violet);
}

.scaleGroup .scaleOption[aria-pressed=true] {
	background-color: var(--violet);
	color: #fff !important;
}

.scaleGroup .scaleOption[aria-pressed=true][disabled] {
	background-color: #ccc;
	border-color: silver !important;
	color: gray !important;
}

.singleChoice > label {
	margin-top: 5px;
}

.singleChoice > label + label {
	margin-top: 10px;
}

.singleChoice > label > span {
	line-height: 1.2;
}

.singleChoice > label > span:first-child {
	padding: 0 9px;
}

.multiChoice {
	display: flex;;
	flex-flow: column;
}

.multiChoice > label {
	margin-top: 5px;
}

.multiChoice > label + label {
	margin-top: 10px;
}

.multiChoice > label > span {
	line-height: 1.2;
}

.multiChoice > label > span:first-child {
	padding: 0 9px;
}

div.scaleLabels {
	display: flex;
	justify-content: space-between;
	color: #aaa;
	font-size: 80%;
	padding: 10px 0 0 0;
}

tr.scaleLabels {
	color: #aaa;
	font-size: 80%;
}

tr.scaleLabels > td:first-child {
}

tr.scaleLabels > td:last-child {
	text-align: right;
}

.textarea {
	margin: 0 !important;
	padding: 0 !important;
	width: 100%;
}

.textarea textarea {
	width: 100%;
	border-color: #ddd !important;
}

.relayAlert, button.relayAlert {
	margin-bottom: 10px;
}

.relayInfo {
	color: #fff;
	font-weight: 600;
	margin: 20px 20px;
	padding: 20px 10px;
	text-align: center;
}

.sortedItem {
	border: 1px solid #ddd;
	background-color: #fff;
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.removedSortedItem {
	color: #666;
	background-color: #eee;
}

.actionButtonContainer {
	display: flex;
	justify-content: space-between;
	margin: 10px 0px;
	padding: 5px;
	background-color: #eee;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

div.unsortedItems {
	display: flex;
	justify-content: center;
	color: #aaa;
	font-size: 80%;
	padding: 10px 0 10px 0;
	border-bottom: 1px solid #ddd;
	margin-bottom: 10px;
}

.fullScreenContainer {
	background-color: var(--violet);
	min-height: 100vh;
	width: 100%;
	padding: 2%;
	box-sizing: border-box;
}

.fullScreenContainer .logo {
	height: max(10px, min(1200px, 100vw) * 0.04);
	margin-left: 0px;
	margin-top: 0px;
	margin-bottom: max(10px, min(1200px, 100vw) * 0.04);
}

.fullScreenContainer li button {
	color: white !important;
}

.fullScreenContainer strong {
	font-weight: normal;
}

.fullScreenContainer .fullScreenItem .container {
	display: flex;
	justify-content: center;
	border-radius: 30px;
	background-color: #4E3377;
	color: white;
	margin-bottom: 30px;
	padding: max(10px, min(100vh / 80, 100vw / 80));
}

.fullScreenContainer .fullScreenItem .column {
	width: 50%;
	marign: auto;
}

.fullScreenContainer .fullScreenItem .title {
	font-size: max(15px, min(100vh / 30, 100vw / 30));
	line-height: calc(max(15px, min(100vh / 30, 100vw / 30)) * 1.3);
	margin-bottom: max(15px, min(100vh / 30, 100vw / 30));
	color: white;
}

.fullChartItem {

	padding: 20px;
	margin: 20px;
	box-sizing: border-box;
	border-bottom: 1px solid #D4AFEE;

	.chartTitle {
		font-size: max(15px, min(100vh / 40, 100vw / 40));
		line-height: calc(max(15px, min(100vh / 40, 100vw / 40)) * 1.3);
		color: var(--live-white);
		padding-bottom: calc(max(12px, min(100vh / 80, 100vw / 80)) * 0.8);
		margin-bottom: calc(max(12px, min(100vh / 80, 100vw / 80)) * 1.3);
	}

	.chart {

	}

	li button {
		color: white !important;
	}

}

.fullChartItem:last-child {
	border-bottom: 0px;
}
