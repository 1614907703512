:root {
    --violet: #210055;
    --cyan: #2ac0ce;
    --yellow: #fcc037;
    --red: #f04447;
    --green: #08bd5a;
    --gray: #aaaaaa;
    --black: #000000;
    --white: #ffffff;

    --dark-red: #b04417;
    --dark-green: #088d2a;
    --dark-yellow: #dca017;
    --dark-violet: #010035;
    --dark-gray: #666666;

    --light-violet: #9eaed9;
    --light-cyan: #aae6eb;
    --light-yellow: #fee6af;
    --light-red: #f9b4b5;
    --light-green: #9ce5bd;
    --light-gray: #dddddd;
    --ultra-light-gray: #f5f5f5;

    --live-violet: #210055;
    --live-yellow: #fcc037;
    --live-cyan: #4fc5cb;
    --live-green: #a6f5b4;
    --live-white: #ffffff;
    --live-light-cyan: #d4afee;

    --audit-white: #ffffff;
    --audit-blue: #210055;
    --audit-amber: #FCC037;
    --audit-turquoise : #4FC5CB;
    --audit-light-green: #A6F5B4;
    --audit-purple: #D4AFEE;
    --audit-light-purple: #F9EDFF;
    --audit-light-gray: #F5F5F5;
}
