@import 'colors.css';

.uploadedFile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.uploadedFile div {
    height: 25px;
}

.uploadedFileTitle {
    font-weight: 600;
    margin: 10px 5px;
}

.uploadedFileEditable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #ddd;
}

.uploadedFileEditable:hover {
     background-color: #fff4e0;
}

.uploadedFileEditable div {
    display: flex;
    align-items: center;
}

.filesAction {
    display: flex;
    justify-content: flex-end;
}

.dropZone {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: repeating-linear-gradient(
            45deg,
            #eee,
            #eee 10px,
            #fff 10px,
            #fff 20px
    );
    border: 2px solid #ddd;
    border-radius: 10px;
}

.dropZone p {
    font-weight: 400;
    color: #666;
    text-align: center;
    font-size: 0.9rem;
    line-height: 1.2rem;
    padding: 10px 10px;
}
