:root {
    --strat-white: #ffffff;
    --strat-yellow: #FCC036;
    --strat-cyan: #4EC5CB;
    --strat-violet: #D4ADEE;
    --strat-violet-light: #E5D9F4;
    --strat-violet-ultralight: #EDE7F6;
    --strat-green: #A5F5B5;
    --strat-blue: #220055;
    --strat-gray: #707070;
    --strat-light-gray: #a0a0a0;
    --strat-ultralight-gray: F9F9F9;

    --page-background-color: var(--strat-ultralight-gray);
    --background-color: var(--strat-white);
    --border-color: var(--strat-ultralight-gray);
}
