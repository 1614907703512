@import '../colors.css';
@import '../../fonts/Proxima-Nova_Regular.css';
@import '../../fonts/PPMori.css';

body {
    margin: 0px;
    padding: 0px;
}

body * {
    font-family: 'PPMori', Arial, Courier, sans-serif !important;
}

.container {
    /* background-color: var(--ultra-light-gray); */
    /* background-image: url("../images/background.png"); */
    background-color: var(--audit-light-gray);
    background-size: cover;
    background-position: right top;
    min-height: 100vh;
}

.container.noBackground {
    background-image: none;
}

.container .link {
    color: var(--violet);
    cursor: pointer;
    text-decoration: none;
}

.container .link:hover {
    color: var(--dark-violet);
    text-decoration: underline;
}

.container * {
    font-family: 'PPMori', Arial, Courier, sans-serif !important;
    color: var(--audit-blue);
}

.questionTitle {
    font-weight: 600;
    line-height: 24px;
    margin: 10px 0px 20px 0px;
    font-size: 24px;
    span {
        font-weight: 400;
        font-size: 14px;
    }
}

.listTitle {
    font-weight: 600;
}

.listInfo {
    font-size: 0.8rem;
    color: var(--dark-gray);
}

.pageHeaderContainer {
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    color: var(--audit-blue);
}

.pageHeaderContainer div:nth-child(1) {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--violet);
}

.simpleList {
    border: 0px solid #aaa;
    border-radius: 5px;
}

.simpleList svg {
    cursor: pointer;
}

.simpleList .simpleListHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #666;
    font-weight: 600;
}

.simpleList .simpleListRow {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.simpleList .simpleListRow:hover {
    background-color: #f5f5f5;
}

.simpleList > div {
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px dotted #bbb;
}

.simpleList > div:first-child {
    border-radius: 5px 5px 0px 0px;
}

.simpleList > div:last-child {
    border-bottom: 0px;
    border-radius: 0px 0px 5px 5px;
}

.yellowButton {
    background-color: var(--yellow) !important;
    font-weight: 600 !important;
    color: var(--black) !important;
}

.yellowButton:hover {
    background-color: var(--dark-yellow) !important;
}

.lightGreenRoundedButton {
    background: var(--live-green);
    border-radius: 20px;
    color: var(--live-violet);
}

.whiteRoundedButton {
    background: var(--live-white);
    border-radius: 20px;
    color: var(--live-violet);
}

.listPaging {

    width: 100%;
    display: flex;
    justify-content: space-between;

    .info {
        color: var(--gray);
        padding: 0px 16px;
        font-size: 14px;
    }

}

.listFilter {
    background-color: #fff;
    margin-bottom: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;

    .items {
        width: 100%;
        display: flex;
        gap: 15px;
    }

}

.centeredContent {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.auditLogos {
    position: absolute;
    top: 20px;
    left: calc(50% + 310px);
    display: flex;
    flex-direction: column;

    @media (max-width: 1000px) {
        display: none;
    }
}

.circleButton {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    color: var(--dark-gray);
    background-color: var(--live-green) !important;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--dark-gray);
}

.circleButton:hover {
    color: var(--black);
    background-color: var(--live-green) !important;
    border: 1px solid var(--black);
    filter: drop-shadow(2px 2px 1px #888);
}

.link {
    cursor: pointer;
    color: var(--violet);
    text-decoration: underline;
}

.link:hover {
    font-weight: bold;
}

.langSwitch {
    background: white;
    color: black;
    font-weight: 600;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.strategyContainer {
    background-color: var(--ultra-light-gray);
    background-size: cover;
    background-position: right top;
    min-height: 100vh;
}

.auditStepper {
    background-color: var(--audit-blue);
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 20;
    border: 1px solid var(--audit-blue);
    border-radius: 0px 0px 5px 5px;
    padding: 10px 0px;
    display: flex;
    justify-content: center;

    div.infoContent {
        width: 100%;
        max-width: 760px;
        border-radius: 10px;
        /* box-shadow: 1px 1px 3px #AAA; */
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: var(--audit-blue);
        padding: 15px;
        margin: 0px 10px;

        img.logo {
            margin: auto;
            height: 28px;
        }

        div.steps {
            display: flex;
            padding: 0px 40px;
            gap: 10px;
            box-sizing: border-box;

            div {
                border-radius: 2px;
                height: 5px;
                flex: 1;
            }
            div.answered {
                background-color: var(--audit-purple);
            }
            div.unanswered {
                background-color: var(--audit-white);
            }
        }
    }
}

.micrositePagination {
    .content {
        display: flex;
        gap: 10px;
        align-items: center;
        div.steps {
            display: flex;
            padding: 0px 40px;
            gap: 10px;
            box-sizing: border-box;
            width: 100%;

            div {
                border-radius: 2px;
                height: 6px;
                flex: 1;
                cursor: pointer;
            }
            div.active {
                background-color: var(--audit-purple);
            }
            div.inactive {
                background-color: var(--audit-white);
            }
        }
        div.buttons {
            display: flex;
            gap: 10px;
            align-items: center;
            div.button {
                height: 30px;
                width: 30px;
                border-radius: 15px;
                border: 0px solid var(--audit-blue);
                background-color: var(--audit-purple);
                color: var(--audit-blue);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                * {
                    font-size: 16px;
                }
            }
            div.button:hover {
                background-color: var(--audit-blue);
            }
            div.button:hover path {
                color: var(--audit-white) !important;
            }
            div.disabledButton {
                height: 30px;
                width: 30px;
                border-radius: 15px;
                border: 0px;
                background-color: #dddddd;
                color: #666666;
                display: flex;
                align-items: center;
                justify-content: center;
                * {
                    font-size: 16px;
                }
            }
        }
    }
}

.remoteController {

    .remotePageTitle {
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: 600;
        .questionOrder {
            font-weight: 300;
            margin-bottom: 10px;
        }
    }

    .buttons {
        display: flex;
        width: 100%;
        gap: 15px;
        margin: 10px 0 20px 0;
        div {
            flex: 1;
        }
        .button {
            flex: 1;
            height: 40px;
            border-radius: 20px;
            border: 1px solid var(--audit-blue);
            background-color: var(--audit-white);
            color: var(--audit-blue);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            * {
                font-size: 16px;
            }
            &:hover {
                background-color: var(--audit-blue);
                color: var(--audit-white);
            }
            &.turnOn {
                background-color: var(--audit-purple);
            }
        }
    }

    .votersCount {
        color: var(--audit-blue);
        font-size: 15px;
        margin: 20px 0 10px 0;
    }
}

.pollListItem {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    border: 1px solid #ddd;
    margin: 5px;
    position: relative;
    cursor: pointer;
    border-radius: 5px;

    div {
        text-align: left;
    }
}

.pollListItem:hover {
    border: 1px solid #aaa;
    background-color: #f5f5f5;
}

.pollListItem .hoverOnly {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 5px 10px;
    visibility: hidden;
    border-radius: 0 0 0 10px;
    color: var(--white);
}

.pollListItem:hover .hoverOnly {
    visibility: visible;
}

.loggedUser {
    font-size: 0.9rem;
    font-weight: 600;
    text-align: right;
    margin-right: 10px;
    color: var(--audit-white);
}

.loggedUser span {
    color: var(--audit-white);
}
