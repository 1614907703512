@import 'colors.css';

.episodeItemName {
	display: flex;
	align-items: center;
}

.episodeItemName svg {
	margin-right: 5px;
}

.episodeItemButtons {
	display: flex;
	align-items: center;
}