@import 'colors.css';
@import '../fonts/Proxima-Nova_Regular.css';
@import '../fonts/Proxima-Nova_Extrabold.css';
@import '../fonts/Proxima-Nova_Bold.css';

.liveLayoutContainer {
    min-height: 100vh;
    max-width: 1600px;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.liveLayoutContainer .logo {
    height: max(10px, min(1200px, 100vw) * 0.030);
    margin-left: 0px;
    margin-top: 20px;
}

.liveLayoutContainer .decoContainer {
    position: absolute;
    z-index: 10;
    overflow: hidden;
    right: 0px;
    top: 0px;
    height: min(50vw, 50vh);
    width: min(50vw, 50vh);
}

.liveLayoutContainer .decoContainer .decoImage {
    height: 150%;
    width: 300%;
    margin-top: -50%;
    margin-left: -60%;
}

.liveLayoutContainer .title {
    padding-top: max(15px, 100vh * 0.055);
    font-size: max(30px, min(1200px, 100vw, 120vh) * 0.05);
    padding-bottom: max(15px, 100vh * 0.035);
    font-weight: 600;
    color: white;
}

.liveLayoutContainer .subtitle {
    font-size: max(16px, min(1200px, 100vw, 120vh) * 0.025);
    line-height: calc(max(16px, min(1200px, 100vw, 120vh) * 0.025) * 1.3);
    color: white;
}

.liveLayoutContainer .smallsubtitle {
    font-size: max(12px, min(1200px, 100vw, 120vh) * 0.020);
    line-height: calc(max(12px, min(1200px, 100vw, 120vh) * 0.020) * 1.3);
    color: white;
}

.liveLayoutContainer .tinysubtitle {
    margin-top: calc(max(16px, min(1200px, 100vw, 120vh) * 0.030) * 1.3);
    font-size: max(8px, min(1200px, 100vw, 120vh) * 0.015);
    line-height: calc(max(8px, min(1200px, 100vw, 120vh) * 0.015) * 1.3);
    color: white;
}

.liveLayoutContainer .backButton {
    padding-top: max(20px, 100vh * 0.03);
}

.liveLayoutContainer .content {
    position: relative;
    z-index: 100;
    padding-top: max(20px, 100vh * 0.05);
    padding-bottom: max(20px, 100vh * 0.03);
}

.liveLayoutContainer .episodesTitle {
    font-size: max(20px, min(1200px, 100vw, 120vh) * 0.025);
    padding-bottom: max(15px, min(100vh, 120vh) * 0.010);
    font-weight: bold;
    color: var(--live-white);
}

.liveLayoutContainer .episodesDesc {
    font-size: max(20px, min(1200px, 100vw, 120vh) * 0.015);
    color: var(--live-white);
}

.liveLayoutContainer .footer {
    display: flex;
    justify-content: space-between;
    /* margin-top: max(45px, 100vh * 0.030); */
    align-items: center;
}

.liveLayoutContainer .footer .copy {
    font-size: max(12px, min(1200px, 100vw, 120vh) * 0.015);
    color: var(--live-white);
}

.liveLayoutContainer .footer .partners {
    color: var(--live-white);
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: max(12px, min(1200px, 100vw, 120vh) * 0.015);
}

.liveLayoutContainer .footer .partners div {
    margin-right: 20px;
}

.liveLayoutContainer .footer .partners img {
    height: max(15px, min(1200px, 100vw, 120vh) * 0.045);
}

.liveLayoutContainer .dashboardTiles {
    padding-top: max(20px, 100vh * 0.03);
    display: flex;
}

.liveLayoutContainer .dashboardTiles .dashboardTile {
    padding: 20px;
    border-left: 1px solid white;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.liveLayoutContainer .dashboardTiles .dashboardTile .icon {
    padding-bottom: max(20px, 100vh * 0.045);
}

.liveLayoutContainer .dashboardTiles .dashboardTile .number {
    padding-top: 0px;
    font-size: max(25px, min(1200px, 100vw, 120vh) * 0.04);
    padding-bottom: max(20px, min(100vh, 120vh) * 0.015);
    font-weight: bold;
    color: var(--live-white);
}

.liveLayoutContainer .dashboardTiles .dashboardTile .title {
    padding-top: 0px;
    font-size: max(20px, min(1200px, 100vw, 120vh) * 0.025);
    padding-bottom: max(20px, min(100vh, 120vh) * 0.015);
    font-weight: bold;
    color: var(--live-white);
}

.liveLayoutContainer .dashboardTiles .dashboardTile .desc {
    font-size: max(15px, min(1200px, 100vw, 120vh) * 0.015);
    line-height: calc(max(15px, min(1200px, 100vw, 120vh) * 0.015) * 1.4);
    color: var(--live-white);
}

.liveLayoutContainer .dashboardTiles .dashboardTile .button {
    padding-top: max(20px, 100vh * 0.045);
}

.liveLayoutContainer .dashboardTiles .dashboardTile .secondButton {
    padding-top: max(10px, 100vh * 0.02);
}


.liveLayoutContainer .dashboardTiles .dashboardTile .disabled .title, .desc, .number {
    color: rgba(255, 255, 255, 0.5);
}

.liveLayoutContainer .faqGroup {
    margin-bottom: max(10px, 100vh * 0.015);
}

.liveLayoutContainer .faqGroup .groupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: var(--live-white);
    color: var(--live-violet);
    border-radius: 20px;
    padding: 40px;
    font-size: max(20px, min(1200px, 100vw) * 0.02);
    line-height: calc(max(15px, min(1200px, 100vw) * 0.015) * 1.3);
    font-weight: bold;
}

.liveLayoutContainer .faqGroup .faqItem {
    background-color: #4E3377;
    color: white;
    border-radius: 20px;
    padding: 20px 40px;
    font-size: max(15px, min(1200px, 100vw) * 0.015);
    line-height: calc(max(15px, min(1200px, 100vw) * 0.015) * 1.4);
    margin-top: max(10px, 100vh * 0.015);
}

.liveLayoutContainer .faqGroup .faqItem .link {
    color: #D4AFEE !important;
}

.liveLayoutContainer .faqGroup .faqItem .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.liveLayoutContainer .faqGroup .faqItem .content {
    padding-top: 20px;
    padding-bottom: 0;
}
