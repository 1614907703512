@import 'colors.css';
@import '../fonts/Proxima-Nova_Regular.css';
@import '../fonts/Proxima-Nova_Extrabold.css';
@import '../fonts/Proxima-Nova_Bold.css';

.introContainer {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: var(--violet);
    color: var(--white);

    .decorationImage {
        position: absolute;
        bottom: 0px;
        right: 0px;
        z-index: -200;
        max-height: 80vh;
        maxWidth: 50%;
    }

    .logo {
        position: absolute;
        height: 7vh;
        top: 7vh;
        left: 5vw;
    }

    .content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-top: 30vh;
        padding-left: 5vw;
        height: 70vh;

        .title {
            font-size: 8vh;
            font-weight: 600;
            font-family: 'ProximaNovaWebExtrabold', Arial, Courier, sans-serif !important;
            margin-bottom: 5vh;
            line-height: 10vh;
            letter-spacing: 0px;
        }

        .subtitle {
            font-size: 6vh;
            font-weight: 600;
            font-family: 'ProximaNovaWebBold', Arial, Courier, sans-serif !important;
            max-width: 50%;
            line-height: 7vh;
            letter-spacing: 0px;
        }

        .countdown {
            font-size: 4vh;
            font-weight: 400;
            font-family: 'ProximaNovaWebBold', Arial, Courier, sans-serif !important;
            max-width: 50%;
            line-height: 4vh;
            letter-spacing: 0px;
            padding-bottom: 8vh;
        }

        .register {
            font-size: 3vh;
            font-weight: 400;
            font-family: 'ProximaNovaWebBold', Arial, Courier, sans-serif !important;
            max-width: 50%;
            line-height: 4vh;
            letter-spacing: 0px;
            padding-bottom: 8vh;
        }

        .register a {
            color: var(--live-green);
        }
    }
}


