@import 'stratairu-colors.css';

.layoutContainer {
    min-height: 100vh;
    background-color: rgba(0,0,0,0.02);
    padding: 10px;
    display: flex;
    box-sizing: border-box;
}

.pageContainer {
    display: flex;
    flex-direction: row;
}

.menuContainer {
    height: 90vh;
    min-width: 190px;
    background-color: var(--strat-white);
    border: 1px solid var(--border-color);
    border-radius: 31px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    position: relative;
}

.menuContainer .menuTopContainer, .menuBottomContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menuContainer .optionsContainer {
    width: 180px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.menuContainer .optionsContainer .option {
    border-radius: 27px;
    min-height: 49px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--strat-blue);
    text-align: center;
}

.menuContainer .optionsContainer .selectedOption {
    background-color: var(--strat-blue);
    color: var(--strat-white);
    font-weight: 600;
}

.menuContainer .optionsContainer .option:hover {
    background-color: var(--strat-blue);
    color: var(--strat-white);
}

.menuContainer .menuStrategiesContainer {
    padding: 10px;
    /* border: 1px solid red; */
    background-color: white;
    filter: drop-shadow(#aaa 0rem 0rem 1px);
    border-radius: 15px;
    width: calc(100% - 20px);
    box-sizing: border-box;
    /* position: absolute; */
}

.menuContainer .menuStrategiesTitle {
    color: var(--strat-blue);
    border-bottom: 1px solid var(--strat-blue);
    font-size: 18px;
    display: flex;
    align-items: center;
}

.menuContainer .menuStrategiesItem {
    color: var(--strat-blue);
    font-size: 12px;
    cursor: pointer;
    padding: 10px 0px;
}

.menuContainer .menuStrategiesItem.menuStrategiesItemSelected {
    font-weight: 800;
}


.pageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 160px;
}

.label {
    color: var(--strat-blue);
    font-size: 20px; /* 29px */
    font-weight: bold;
    border-bottom: 1px solid var(--strat-blue);
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
}

.cardContainer {
    /* background-color: red; */
    width: 100%;
    min-height: 85px;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;
}

.cardContainer:hover .content {
    filter: drop-shadow(var(--strat-blue) 0rem 0rem 1px);
}

.cardContainer .content {
    background-color: var(--strat-white);
    border-radius: 15px;
    filter: drop-shadow(#aaa 0rem 0rem 1px);
    min-height: 85px;
    margin-right: 50px;
    padding-right: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    text-align: left;
}

.cardContainer .content .icon {
    margin-left: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.cardContainer .content .info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 25px;
    gap: 10px;
    color: var(--strat-blue);
}

.cardContainer .content .info2 {
    line-height: 25px;
    gap: 10px;
    color: var(--strat-blue);
    text-align: left;
}

.cardContainer .flag {
    width: 100px;
    position: absolute;
    height: 40px;
    width: 131px;
    right: 0px;
    top: calc(50% - 20px);
    border-radius: 40px 2px 2px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardContainer .statusTile {

}

.NEW {
    background-color: var(--strat-cyan) !important;
    color: var(--strat-white) !important;
}

.DRAFT {
    background-color: var(--strat-yellow) !important;
    color: var(--strat-blue) !important;
}

.PUBLIC {
    background-color: var(--strat-violet) !important;
    color: var(--strat-white) !important;
}

.FINISHED {
    background-color: var(--strat-blue) !important;
    color: var(--strat-white) !important;
}

.searchContainer {
    height: 80px;
    position: relative;
    max-width: 450px;
    width: 100%;
}

.searchContainer * {
    border-radius: 40px !important;
}

.searchContainer input {
    background-color: white;
    padding-left: 80px;
    filter: drop-shadow(#aaa 0rem 0rem 1px);
}

.searchContainer .icon {
    position: absolute;
    top: 0px;
    height: 56px;
    width: 56px;
    background-color: var(--strat-blue);
    color: var(--strat-white);
    display: flex;
    align-items: center;
    justify-content: center;
}

.horizontalOptionsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.horizontalOptionsContainer .option {
    border-radius: 19px;
    min-height: 26px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--strat-blue);
    font-size: 15px; /* 18px */
    white-space: nowrap;
    padding: 0px 10px;
    font-weight: 400;
}

.horizontalOptionsContainer .option:hover {
    background-color: var(--strat-blue);
    color: var(--strat-white);
}

.horizontalOptionsContainer .option.selected {
    background-color: var(--strat-blue);
    color: white;
}

.elementCard {
    background-color: white;
    padding: 10px;
    filter: drop-shadow(#aaa 0rem 0rem 1px);
    border-radius: 15px;
    width: 254px;
    color: var(--strat-blue);
    box-sizing: border-box;
}

.elementCard .title {
    font-size: 16px;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--strat-blue);
    margin-bottom: 15px;
    font-weight: 600;
}

.elementCard .desc {
    font-size: 16px;
    line-height: 24px;
}

.elementCard .statusesContainer {
    padding: 10px;
}

.elementCard .statusesContainer div {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    margin-bottom: 10px;
}

.cardList {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 20px;
}

.supportElementContainer {
    position: relative;
    border-radius: 20px;
    border: 1px solid var(--strat-blue);
    font-size: 26px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.supportElementContainer div:nth-child(1) {
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: var(--strat-blue);
    color: var(--strat-white);
    border-radius: 20px;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comboContainer {
    position: relative;
    box-sizing: border-box;
}

.comboContainer.comboContainerOpen {
}

.comboContainer .comboOptions {
    position: absolute;
    background-color: white;
    filter: drop-shadow(var(--strat-blue)  0px 0px 2px);
    border-radius: 15px;
    width: 100%;
    z-index: 100;
}

.comboContainer .comboOptions.comboDown {
    top: 0px;
    left: 0px;
}

.comboContainer .comboOptions.comboUp {
    bottom: 0px;
    left: 0px;
}

.comboContainer .comboItem {
    padding: 5px 20px;
    cursor: pointer;
}

.comboContainer .comboLabel {
    padding: 5px 5px 5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.comboContainer .comboItem:hover {
    font-weight: 600;
}

.currentStrategy {
    text-align: center;
    font-weight: 600;
}

.logoutLink {
    padding-top: 20px;
    color: var(--strat-blue);
    cursor: pointer;
    font-size: 12px;
}

.logoutLink:hover {
    text-decoration: underline;
}

.faqGroup {
    margin-bottom: max(10px, 100vh * 0.015);
}

.faqGroup .groupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: var(--strat-blue);
    color: var(--strat-white);
    border-radius: 20px;
    padding: 40px;
    font-size: max(20px, min(1200px, 100vw) * 0.02);
    line-height: calc(max(15px, min(1200px, 100vw) * 0.015) * 1.3);
    font-weight: bold;
}

.faqGroup .faqItem {
    background-color: var(--strat-violet-light);
    color: var(--strat-blue);
    border-radius: 20px;
    padding: 20px 40px;
    font-size: max(15px, min(1200px, 100vw) * 0.015);
    line-height: calc(max(15px, min(1200px, 100vw) * 0.015) * 1.4);
    margin-top: max(10px, 100vh * 0.015);
}

.faqGroup .faqItem .link {
    color: #D4AFEE !important;
}

.faqGroup .faqItem .header {
    text-align: left;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.faqGroup .faqItem .content {
    text-align: left;
    padding-top: 20px;
    padding-bottom: 0;
}

.circleButton {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    color: var(--strat-white);
    background-color: var(--strat-cyan) !important;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        color: white;
    }
}

.circleButton:hover {
    color: var(--strat-white);
    background-color: var(--strat-cyan) !important;
    filter: drop-shadow(-2px 2px 1px #888);
    transform: translate(1px, -1px);
}
