@import 'colors.css';

.pillarContainer {
    display: flex;
}

.pillarContainer div {
    flex: 1;
}

.pillarElement {
    margin: 5px;
    height: calc(100% - 10px);
    border: 1px solid black;
}

.pillarElement div:nth-child(1) {
    padding: 5px;
    background: var(--violet);
    color: white;
}

.pillarElement div:nth-child(2) {
    padding: 5px;
}

.pillarSupportElement {
    margin: 5px;
    height: 30px;
    display: flex;
    border: 1px solid black;
}

.pillarSupportElement div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-weight: 200;
}

.pillarSupportElement div:nth-child(1) {
    background: #eee;
    border-radius: 5px;
    border-right: 1px solid black;
}

.statusesContainer {
    display: flex;
}

.statusesContainer div {
    flex: 1;
}

.fillingCard {
    flex: 1;
    margin: 5px;
    border: 1px solid black;
}

.fillingCard div:nth-child(1) {
    display: flex;
}

.fillingCard div:nth-child(1) div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid black;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 18px;
}

.fillingCard div:nth-child(1) div:nth-child(2) {
    border-right: 1px solid black;
    border-left: 1px solid black;
}

.fillingCard div:nth-child(1) div:nth-child(3) {
    border-right: 1px solid black;
}

.fillingCard div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    font-weight: 600;
    font-size: 18px;
}
