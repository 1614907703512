@font-face {
	font-family: 'PPMori';
	src: url('PPMori-Regular.woff') format('woff'), url('PPMori-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PPMori';
	src: url('PPMori-Bold.woff') format('woff'), url('PPMori-Bold.woff2') format('woff2');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'PPMori';
	src: url('PPMori-BoldItalic.woff') format('woff'), url('PPMori-BoldItalic.woff2') format('woff2');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'PPMori';
	src: url('PPMori-RegularItalic.woff') format('woff'), url('PPMori-RegularItalic.woff2') format('woff2');
	font-weight: normal;
	font-style: italic;
}

